import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination])

export default class extends Controller {
  static targets = ['container', 'pagination']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      effect: 'fade',
      autoHeight: true,
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: this.paginationTarget,
        clickable: true,
      },
      autoplay: {
        duration: 6000
      }
    })
  }
}
