import { Controller } from 'stimulus'

import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation])

export default class extends Controller {
  static targets = ['next']

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 32,
      navigation: {
        nextEl: this.nextTarget,
      },
    })
  }
}
