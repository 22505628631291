import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination, EffectFade, Controller as SwiperController } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination, EffectFade, SwiperController])

export default class extends Controller {
  static targets = ['next', 'swiperContainer']

  connect() {
    new Swiper(this.swiperContainerTarget, {
      loop: false,
      slidesPerView: 3,
      spaceBetween: 0,
      navigation: {
        nextEl: this.nextTarget
      }
    })
  }
}
