import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination])

export default class extends Controller {
  static targets = ['next', 'container']

  connect() {
    new Swiper(this.containerTarget, {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      autoplay: {
        duration: 6000
      },
      navigation: {
        nextEl: this.nextTarget,
      },
      breakpoints: {
        648: {
          slidesPerView: 1.5,
        },
        1024: {
          slidesPerView: 2.1
        }
      }
    })
  }
}
