import { Controller } from 'stimulus'

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Autoplay, Navigation, Pagination])

export default class extends Controller {
  static targets = ['next']

  connect() {
    new Swiper(this.element, {
      loop: true,
      slidesPerView: 3,
      spaceBetween: 32,
      navigation: {
        nextEl: this.nextTarget,
      },
    })
  }
}
